import React from 'react';
import { withRouter } from 'react-router-dom';

import './CardSelection.scss';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import { withTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

@inject('questionnaireStore', 'topBarStore', 'mobileStore', 'swipeStore')
@observer
class CardSelection extends React.Component {
	@observable
	showPieChart = true;

	@observable
	selectedColorId = '';

	handleButton(questionnaireName) {
		const { history, questionnaireStore } = this.props;

		questionnaireStore.setCurrent(questionnaireName);
		this.props.swipeStore.resetSwipeIndex();

		history.push('/main/questionnaire');
	}

	componentDidMount = () => {
		const { topBarStore } = this.props;
		topBarStore.setTopBarTitle('Kortin valinta');
	};

	@action
	answerCallback = answer => {
		this.showPieChart = false;
		this.selectedColorId = answer;
	};

	@action
	handleBackButton = event => {
		this.selectedColorId = '';
		this.showPieChart = true;
	};

	componentDidUpdate = () => {
		this.props.topBarStore.setTopBarColor(this.selectedColorId);
	};

	componentWillMount = () => {
		document.title = 'Kortin valinta';
	};

	render() {
		const { questionnaireStore } = this.props;
		return (
			<div className="cardSelection ">
				<img className="cardSelectionImage" src={require('./kotisirkus_787px.jpg')} alt="" />

				<div className="neuvokasText">
					Tästä voit valita minkä oman Neuvokas perhe - kortin haluat täyttää seuraavaksi.
				</div>
				<div className="neuvokasText">
					Koululaisen kortti on tarkoitettu koululaisen ja aikuisen yhdessä täytettäväksi. Aikuisten ja neuvolaikäisten
					kortit ovat taas tarkoitettu aikuisten täytettäväksi.
				</div>
				<div className="buttonWrapper">
					{questionnaireStore.getQuestionnaires().map(
						questionnaire =>
							questionnaire.id !== 'meidanperhe' && (
								<Button className="neuvokasButton" onClick={() => this.handleButton(questionnaire.id)}>
									{questionnaire.full}
								</Button>
							)
					)}
				</div>
			</div>
		);
	}
}

export default withRouter(withTranslation()(CardSelection));
