import React from 'react';
import { withRouter } from 'react-router-dom';

import './FeedbackView.scss';
import { observer, inject } from 'mobx-react';
import { toJS, observable, action } from 'mobx';
import NeuvokasPieChart from '../../components/neuvokasPieChart/NeuvokasPieChart';
import { withTranslation } from 'react-i18next';
import { ListItem, List, Button } from '@material-ui/core';
import html2canvas from 'html2canvas';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SwipeableViews from 'react-swipeable-views';
import { has } from 'lodash';
import Divider from '@material-ui/core/Divider';
import Balls from '../../components/balls/Balls';

@inject('questionnaireStore', 'topBarStore', 'swipeStore', 'mobileStore', 'pdfStore', 'drawerStore', 'dialogStore')
@observer
class FeedbackView extends React.Component {
	@observable
	showPieChart = true;

	@observable
	selectedColorId = '';

	@observable
	selectedName = 0;

	@observable
	nameId = 0;

	@action
	answerCallback = (answer, nameId) => {
		console.log('tieodt: ', answer, nameId);
		this.showPieChart = false;
		this.selectedColorId = answer;
		this.nameId = nameId;
	};

	@action
	handleCardSelectButton = event => {
		this.props.history.push('/main/cardsave');
	};

	@action
	handleClickInfoButton = questionId => {
		const { t } = this.props;
		this.props.dialogStore.setInfoModalState(
			true,
			t('info.' + questionId + '.text'),
			t('info.' + questionId + '.link')
		);
	};

	@action
	handleCardManualSwipe = event => {
		this.props.swipeStore.setSwipeIndex(1);
	};

	@action
	handleBackToQuestionsButton = event => {
		this.props.history.push('/main/questionnaire');
	};

	@action
	handleBackButton = event => {
		this.selectedColorId = '';
		this.showPieChart = true;
	};

	handlePrintPdf = () => {
		const { pdfStore, questionnaireStore } = this.props;

		const elem = document.getElementById('printPdf');

		html2canvas(elem, { allowTaint: true, logging: false }).then(canvas => {
			pdfStore.addUrl(
				questionnaireStore.getCurrent(),
				canvas.toDataURL('image/png', canvas.width, canvas.height),
				canvas.height / canvas.width
			);
		});
	};

	handleAnswerTableButton = answer => {
		this.answerCallback(answer);
	};

	componentDidMount = () => {
		this.showPieChart = true;
		this.props.topBarStore.setTopBarTitle('Loppupalaute');

		this.handlePrintPdf();
		this.props.drawerStore.setSideInfoState(true);
		this.props.swipeStore.resetSwipeIndex();
	};

	componentDidUpdate = () => {
		this.props.topBarStore.setTopBarColor(this.selectedColorId);
	};

	componentWillUnmount = () => {
		this.props.drawerStore.setSideInfoState(false);
		this.props.swipeStore.resetSwipeIndex();
	};

	countNullZero = count => {
		if (count === 0) return null;
		return count;
	};

	render() {
		const { questionnaireStore, t, mobileStore, swipeStore } = this.props;
		let colorCount = [];
		colorCount.push(questionnaireStore.getAnswerCount(questionnaireStore.getCurrent(), '1'));
		colorCount.push(questionnaireStore.getAnswerCount(questionnaireStore.getCurrent(), '2'));
		colorCount.push(questionnaireStore.getAnswerCount(questionnaireStore.getCurrent(), '3'));
		const answersByColor = toJS(
			questionnaireStore.getAnswersByColor(questionnaireStore.getCurrent(), this.selectedColorId, this.nameId)
		);
		const openAnswers = toJS(questionnaireStore.getOpenAnswers(questionnaireStore.getCurrent()));

		const noAnswers = colorCount[0] === 0 && colorCount[1] === 0 && colorCount[2] === 0;
		const numbers = [{ name: 'vihreät', id: '1' }, { name: 'keltaiset', id: '2' }, { name: 'punaiset', id: '3' }];
		const count = ['1', '2', '3'];
		const details = questionnaireStore.getDetails(questionnaireStore.getCurrent());
		const adultNames = questionnaireStore.getNames(questionnaireStore.getCurrent());
		return (
			<div className="feedbackView">
				{this.showPieChart ? (
					<div className={mobileStore.isMobile() ? 'feedbackWrapper ' : 'feedbackWrapper neuvokasPaper'}>
						{mobileStore.isMobile() ? (
							<div className="descriptionWrapper neuvokasText">
								Kiitos täyttämisestä. Tältä näyttävät antamanne vastaukset. Valitsemalla piirakasta sektorin pääset
								tarkastelemaan kyseisen värisiä vastauksiasi tarkemmin.
							</div>
						) : (
							<div className="descriptionWrapper neuvokasText">
								Kiitos täyttämisestä. Tältä näyttävät antamanne vastaukset. Valitsemalla piirakasta sektorin tai
								alapuolelta linkin pääset tarkastelemaan kyseisen värisiä vastauksiasi tarkemmin.
							</div>
						)}

						{questionnaireStore.isVisibleDetails() && (
							<div className="ageAndName">
								<div>
									<span className="boldedText">Lapsen nimi: </span>
									{details.name || '-'}
								</div>
								<div>
									<span className="boldedText">Lapsen ikä: </span>
									{details.age || '-'}
								</div>
							</div>
						)}

						{questionnaireStore.isAdultQuestionnaire() && (
							<div className="multiplePieChartContainer">
								{mobileStore.isMobile() && (
									<div className="adultNameSwipeText">Pyyhkäise eri aikuisten vastausten välillä</div>
								)}
								{mobileStore.isMobile() && (
									<SwipeableViews
										style={{ height: '100%' }}
										index={swipeStore.getSwipeIndex()}
										onChangeIndex={this.handleChangeIndex}
										springConfig={{ duration: '1.0s', easeFunction: 'ease', delay: '0s' }}>
										{adultNames.map((nameItem, index) => {
											return (
												<div key={'pie' + nameItem.id} className="pieChartAndNameContainer">
													<div>{nameItem.text}</div>
													<div className="multiplePieChartWrapper">
														<NeuvokasPieChart
															greenAnswerCount={colorCount[0][index] === 0 ? null : colorCount[0][index]}
															yellowAnswerCount={colorCount[1][index] === 0 ? null : colorCount[1][index]}
															redAnswerCount={colorCount[2][index] === 0 ? null : colorCount[2][index]}
															answerCallback={colorId => this.answerCallback(colorId, index)}
															animate={true}
														/>
														{noAnswers &&
															'Et ole valinnut yhtään vastausta, voit palata takaisin muuttamaan vastauksiasi.'}
													</div>
													<div className="listAndAnswerWrapper">
														{!noAnswers && !mobileStore.isMobile() && (
															<ul className="answerButtonList">
																<li className="listItemButton">
																	<Button
																		className="answerButton green"
																		variant="outlined"
																		classes={{ root: 'answerButton green' }}
																		onClick={() => this.answerCallback('1', index)}
																		fullWidth>
																		<Balls color="green" className="ball" /> Vihreitä vastauksia
																		{' ' + colorCount[0][index] || 0}
																	</Button>
																</li>
																<li className="listItemButton">
																	<Button
																		variant="outlined"
																		classes={{ root: 'answerButton yellow' }}
																		onClick={() => this.answerCallback('2', index)}>
																		<Balls color="yellow" className="ball" /> Keltaisia vastauksia
																		{' ' + colorCount[1][index] || 0}
																	</Button>
																</li>
																<li className="listItemButton">
																	<Button
																		variant="outlined"
																		classes={{ root: 'answerButton red' }}
																		onClick={() => this.answerCallback('3', index)}>
																		<Balls color="red" className="ball" /> Punaisia vastauksia
																		{' ' + colorCount[2][index] || 0}
																	</Button>
																</li>
															</ul>
														)}
													</div>
												</div>
											);
										})}
									</SwipeableViews>
								)}
								{!mobileStore.isMobile() &&
									adultNames.map((nameItem, index) => {
										return (
											<div key={'pie' + nameItem.id} className="pieChartAndNameContainer">
												<div>{nameItem.text}</div>
												<div className="multiplePieChartWrapper">
													<NeuvokasPieChart
														greenAnswerCount={this.countNullZero(colorCount[0][index])}
														yellowAnswerCount={this.countNullZero(colorCount[1][index])}
														redAnswerCount={this.countNullZero(colorCount[2][index])}
														answerCallback={colorId => this.answerCallback(colorId, index)}
														animate={true}
													/>
													{noAnswers &&
														'Et ole valinnut yhtään vastausta, voit palata takaisin muuttamaan vastauksiasi.'}
												</div>
												<div className="listAndAnswerWrapper">
													{!noAnswers && !mobileStore.isMobile() && (
														<ul className="answerButtonList">
															<li className="listItemButton">
																<Button
																	className="answerButton green"
																	variant="outlined"
																	classes={{ root: 'answerButton green' }}
																	onClick={() => this.answerCallback('1', index)}
																	fullWidth>
																	<Balls color="green" className="ball" /> Vihreitä vastauksia
																	{' ' + colorCount[0][index] || 0}
																</Button>
															</li>
															<li className="listItemButton">
																<Button
																	variant="outlined"
																	classes={{ root: 'answerButton yellow' }}
																	onClick={() => this.answerCallback('2', index)}>
																	<Balls color="yellow" className="ball" /> Keltaisia vastauksia
																	{' ' + colorCount[1][index] || 0}
																</Button>
															</li>
															<li className="listItemButton">
																<Button
																	variant="outlined"
																	classes={{ root: 'answerButton red' }}
																	onClick={() => this.answerCallback('3', index)}>
																	<Balls color="red" className="ball" /> Punaisia vastauksia
																	{' ' + colorCount[2][index] || 0}
																</Button>
															</li>
														</ul>
													)}
													<div className="neuvokasTopBarTitle openAnswerList">Vastauksesi avoimiin kysymyksiin:</div>
													{openAnswers.map(answer => (
														<div key={answer.questionId}>
															<div className="questionTextWrapper questionItem">
																{t('questions.' + answer.questionId)}
															</div>
															<div className="questionTextWrapper questionAnswer">{answer.answer || '-'}</div>
														</div>
													))}
												</div>
											</div>
										);
									})}
							</div>
						)}
						{!questionnaireStore.isAdultQuestionnaire() && (
							<div className="pieChartContainer">
								<div id="printPie" className="pieChartWrapper">
									<NeuvokasPieChart
										greenAnswerCount={this.countNullZero(colorCount[0])}
										yellowAnswerCount={this.countNullZero(colorCount[1])}
										redAnswerCount={this.countNullZero(colorCount[2])}
										answerCallback={this.answerCallback}
										animate={true}
									/>
									{noAnswers && 'Et ole valinnut yhtään vastausta, voit palata takaisin muuttamaan vastauksiasi.'}
								</div>

								<div className="listAndAnswerWrapper">
									{!noAnswers && (
										<ul className="answerButtonList">
											<li className="listItemButton">
												<Button
													className="answerButton green"
													variant="outlined"
													classes={{ root: 'answerButton green' }}
													onClick={() => this.answerCallback('1')}
													fullWidth>
													<Balls color="green" className="ball" /> Vihreitä vastauksia
													{' ' + colorCount[0] || 0}
												</Button>
											</li>
											<li className="listItemButton">
												<Button
													variant="outlined"
													classes={{ root: 'answerButton yellow' }}
													onClick={() => this.answerCallback('2')}>
													<Balls color="yellow" className="ball" /> Keltaisia vastauksia
													{' ' + colorCount[1] || 0}
												</Button>
											</li>
											<li className="listItemButton">
												<Button
													variant="outlined"
													classes={{ root: 'answerButton red' }}
													onClick={() => this.answerCallback('3')}>
													<Balls color="red" className="ball" /> Punaisia vastauksia
													{' ' + colorCount[2] || 0}
												</Button>
											</li>
										</ul>
									)}
									<div className=" openAnswerList">
										<div className="neuvokasTopBarTitle">Vastauksesi avoimiin kysymyksiin:</div>
										{openAnswers.map(answer => (
											<div key={answer.questionId}>
												<div className="questionTextWrapper questionItem">{t('questions.' + answer.questionId)}</div>
												<div className="questionTextWrapper questionAnswer">{answer.answer || '-'}</div>
											</div>
										))}
									</div>
								</div>
							</div>
						)}
						<div className=" neuvokasButtonWrapper">
							<Button className="backButton neuvokasButton" onClick={this.handleBackToQuestionsButton}>
								<ArrowBackIcon className="arrowBackIcon" />
								Takaisin
							</Button>
							<Button className="toCardSelectionButton neuvokasButton" onClick={this.handleCardSelectButton}>
								Eteenpäin
							</Button>
						</div>
					</div>
				) : (
					<div className="questionList neuvokasPaper">
						{questionnaireStore.isAdultQuestionnaire() ? (
							<List className="answerList">
								{answersByColor.map(answer => {
									console.log('answer', answer);
									return (
										<ListItem className={'listItem color-' + this.selectedColorId}>
											<div className="liContainer">
												{t('questions.' + answer.questionId)}
												{questionnaireStore.isFamilyQuestionnaire() && (
													<IconButton
														color="primary"
														aria-label="show info"
														onClick={() => this.handleClickInfoButton(answer.questionId)}>
														<InfoIcon className="infoIcon" />
													</IconButton>
												)}
											</div>
											{has(answer, 'followUpAnswer') && answer.followUpAnswer[this.nameId] !== '' && (
												<div className="followUpContainer">
													<div className="questionText">{t('questions.' + answer.followUpQuestion)}</div>
													<div>{answer.followUpAnswer[this.nameId]}</div>
												</div>
											)}
										</ListItem>
									);
								})}
							</List>
						) : (
							<List className="answerList">
								{answersByColor.map(answer => {
									return (
										<ListItem className={'listItem color-' + this.selectedColorId}>
											<div className="liContainer">
												{t('questions.' + answer.questionId)}
												{questionnaireStore.isFamilyQuestionnaire() && (
													<IconButton
														color="primary"
														aria-label="show info"
														onClick={() => this.handleClickInfoButton(answer.questionId)}>
														<InfoIcon className="infoIcon" />
													</IconButton>
												)}
											</div>
											{has(answer, 'followUpAnswer') && answer.followUpAnswer !== '' && (
												<div className="followUpContainer">
													<div className="questionText">{t('questions.' + answer.followUpQuestion)}</div>
													<div>{answer.followUpAnswer}</div>
												</div>
											)}
										</ListItem>
									);
								})}
							</List>
						)}
						<Divider />
						<div className="listButtonWrapper">
							<Button
								className="backButton neuvokasButton"
								variant="contained"
								color="primary"
								onClick={this.handleBackButton}>
								Takaisin
							</Button>
						</div>
					</div>
				)}
				<div id="printPdf" className="questionList">
					<div className="fullHeadline">{questionnaireStore.getCurrentQuestionnaireFull()}</div>

					{questionnaireStore.isVisibleDetails() && (
						<div className="ageAndName">
							<div>Lapsen nimi: {details.name || '-'}</div> <div>Lapsen ikä: {details.age || '-'}</div>
						</div>
					)}
					{questionnaireStore.isAdultQuestionnaire()
						? adultNames.map((nameItem, adultIndex) => {
								return (
									<div>
										{nameItem.text}
										{count.map((colorId, index) => {
											return (
												<div key={colorId + index} className="listWrapper">
													{colorCount[index][adultIndex] !== 0 && (
														<div className="colorText">{numbers[index].name + ' vastaukset'}</div>
													)}

													<List className="answerList">
														{questionnaireStore
															.getAnswersByColor(questionnaireStore.getCurrent(), colorId, this.nameId)
															.map(answer => {
																return (
																	<ListItem className={'color-' + colorId}>
																		<div className="liContainer">
																			{t('questions.' + answer.questionId)}
																			<IconButton color="primary" aria-label="show info">
																				<InfoIcon className="infoIcon" />
																			</IconButton>
																		</div>
																		{has(answer, 'followUpAnswer') && answer.followUpAnswer[this.nameId] !== '' && (
																			<div className="followUpContainer">
																				<div className="questionText">{t('questions.' + answer.followUpQuestion)}</div>
																				<div>{answer.followUpAnswer[this.nameId]}</div>
																			</div>
																		)}
																	</ListItem>
																);
															})}
													</List>
												</div>
											);
										})}
									</div>
								);
						  })
						: count.map((colorId, index) => {
								return (
									<div key={colorId + index} className="listWrapper">
										{colorCount[index] !== 0 && <div className="colorText">{numbers[index].name + ' vastaukset'}</div>}
										<List className="answerList">
											{questionnaireStore
												.getAnswersByColor(questionnaireStore.getCurrent(), colorId, this.nameId)
												.map(answer => {
													return (
														<ListItem className={'color-' + colorId}>
															<div className="liContainer">
																{t('questions.' + answer.questionId)}
																<IconButton color="primary" aria-label="show info">
																	<InfoIcon className="infoIcon" />
																</IconButton>
															</div>
															{has(answer, 'followUpAnswer') && answer.followUpAnswer !== '' && (
																<div className="followUpContainer">
																	<div className="questionText">{t('questions.' + answer.followUpQuestion)}</div>
																	<div>{answer.followUpAnswer}</div>
																</div>
															)}
														</ListItem>
													);
												})}
										</List>
									</div>
								);
						  })}

					{!questionnaireStore.isAdultQuestionnaire && (
						<div className="openAnswerWrapper">
							<div className="neuvokasTopBarTitle openAnswerList">Vastauksesi avoimiin kysymyksiin:</div>
							{openAnswers.map(answer => (
								<div key={answer.questionId} className="openAnswerTextWrapper">
									<div className="questionTextWrapper questionItem">{t('questions.' + answer.questionId)}</div>
									<div className="questionTextWrapper questionAnswer">{answer.answer || '-'}</div>
								</div>
							))}
						</div>
					)}
					<div className="logoWrapper">
						<img className="logoLeft" src={require('./Neuvokas_ensisijainen_logo1_rgb_c.png')} alt="" />
						<img className="logoRight" src={require('./sl_logo_fi_rgb_c.png')} alt="" />
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withTranslation()(FeedbackView));
