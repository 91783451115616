import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import MainView from './views/mainview/Mainview';
import './App.css';
import SplashView from './views/splashView/SplashView';

class App extends Component {
	render() {
		return (
			<div className="App">
				<Switch>
					<Redirect exact={true} from="/" to="/splash" />
					<Route path="/splash" component={SplashView} />
					<Route path="/main" component={MainView} />
				</Switch>
			</div>
		);
	}
}

export default App;
