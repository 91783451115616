import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import './SplashView.scss';

class SplashView extends React.Component {
	handleButton = event => {
		this.props.history.push('/main/');
	};

	render() {
		const { t } = this.props;
		return (
			<div className="neuvokas-frontpage">
				<img className="neuvokasLogo" src={require('./Neuvokas_toissijainen_logo6_rgb_w.png')} alt="neuvokaslogo" />

				<img className="frontpage-image" alt="" src={require('./neuvokasukkeli.png')} />
				<Button
					className="frontpageButton neuvokasSplashButton"
					variant="contained"
					color="primary"
					onClick={this.handleButton}>
					{t('views.splash.splashButton')}
				</Button>
				<img className="sydanliittoLogo" alt="" src={require('./sl_logo_fi_rgb_w.png')} />
				<div className="osaText">Neuvokas perhe on osa Sydänliiton toimintaa.</div>
			</div>
		);
	}
}

export default withRouter(withTranslation()(SplashView));
