import React from 'react';

import './Balls.scss';
import { observer } from 'mobx-react';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

@observer
class Balls extends React.Component {
	render() {
		const { color, selected, className } = this.props;
		let ballClass = 'checkmark ' + color + ' ';
		ballClass += selected ? 'checked' : '';
		return (
			<span className={ballClass + ' ' + className}>
				{color === 'green' ? (
					<CheckIcon className="white" />
				) : color === 'yellow' ? (
					<SearchIcon className="white" />
				) : (
					<CloseIcon className="white" />
				)}
			</span>
		);
	}
}
export default Balls;
