import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import OpenQuestion from '../../components/openQuestion/OpenQuestion';
import ChoiceQuestion from '../../components/choiceQuestion/ChoiceQuestion';
import SectionWrapper from '../../components/sectionWrapper/SectionWrapper';

import './QuestionnaireView.scss';
import { observer, inject } from 'mobx-react';
import { observable, action, toJS } from 'mobx';
import SwipeableViews from 'react-swipeable-views';
import NameSelector from '../../components/nameSelector/NameSelector';
import TextField from '@material-ui/core/TextField';

@inject('questionnaireStore', 'swipeStore', 'mobileStore', 'topBarStore', 'pdfStore', 'drawerStore')
@observer
class QuestionnaireView extends React.Component {
	@observable
	width = 0;

	@observable
	reactSwipeEl;

	@action
	handleChange = (param, question) => e => {
		if (param === 'age') {
			if (e.target.value > 10 || e.target.value < 0) return;
		}
		this.setState({ [param]: e.target.value });

		question.nameAndAge[param] = e.target.value;
	};

	handleButton = event => {
		const { history } = this.props;

		this.props.questionnaireStore.setCompleted();

		history.push('/main/questionnaire-feedback');
	};

	handleChangeIndex = index => {
		this.props.swipeStore.setSwipeIndex(index);
	};

	componentDidMount = () => {
		this.props.topBarStore.setTopBarTitle(this.props.questionnaireStore.getCurrentQuestionnaireFull());
		this.props.drawerStore.setSideInfoState(true);
		this.props.topBarStore.resetTopBarColor();
	};

	componentWillUpdate = () => {
		this.props.topBarStore.setTopBarTitle(this.props.questionnaireStore.getCurrentQuestionnaireFull());
	};

	componentWillMount = () => {
		document.title = this.props.questionnaireStore.getCurrentQuestionnaireFull();
	};

	componentWillUnmount = () => {
		this.props.drawerStore.setSideInfoState(false);
	};

	render() {
		const { questionnaireStore, swipeStore, mobileStore } = this.props;

		if (mobileStore.isMobile()) {
			return (
				<div className="questionnaireWrapperMobile">
					<SwipeableViews
						style={{ height: '100%' }}
						index={swipeStore.getSwipeIndex()}
						onChangeIndex={this.handleChangeIndex}
						springConfig={{ duration: '1.0s', easeFunction: 'ease', delay: '0s' }}>
						{questionnaireStore.getAllQuestionnaireQuestions(questionnaireStore.getCurrent()).map((question, index) => {
							if (toJS(question.type) === 'choice') {
								return (
									<div key={question.questionId} className="questionWrapperMobile">
										<ChoiceQuestion
											question={question}
											questionCount={
												questionnaireStore.getAllQuestionnaireQuestions(questionnaireStore.getCurrent()).length
											}
											questionIndex={index}
										/>
									</div>
								);
							} else if (question.type === 'openText') {
								return (
									<div key={question.questionId} className="questionWrapperMobile">
										<OpenQuestion
											question={question}
											questionCount={
												questionnaireStore.getAllQuestionnaireQuestions(questionnaireStore.getCurrent()).length
											}
											questionIndex={index}
										/>
									</div>
								);
							} else if (question.type === 'nameAndAge') {
								return (
									<div key={question.questionId} className="questionWrapper">
										<div
											className={
												mobileStore.isMobile() ? 'ageAndNameContainer neuvokasPaperMobile' : 'ageAndNameContainer'
											}>
											<div className="nameContainer">Lapsen tiedot</div>
											<div className="textfieldContainer">
												<TextField
													id="child-name"
													label="Nimi"
													className="text-field-name"
													value={question.nameAndAge.name}
													onChange={this.handleChange('name', question)}
													margin="normal"
													type="text"
													InputLabelProps={
														mobileStore.isMobile()
															? {
																	className: 'neuvokasQuestion',
																	shrink: true,
																	classes: {
																		root: 'cssLabel'
																	}
															  }
															: {
																	className: 'neuvokasQuestion',
																	classes: {
																		root: 'cssLabel'
																	}
															  }
													}
													InputProps={{
														className: 'textInput'
													}}
												/>
												<TextField
													id="child-age"
													label="Ikä"
													className="text-field-age"
													value={question.nameAndAge.age}
													onChange={this.handleChange('age', question)}
													margin="normal"
													type="number"
													InputLabelProps={
														mobileStore.isMobile()
															? {
																	className: 'neuvokasQuestion',
																	shrink: true
															  }
															: { className: 'neuvokasQuestion' }
													}
													InputProps={{
														className: 'textInput',
														inputProps: { min: '0', max: '18' }
													}}
												/>
											</div>
										</div>
										{mobileStore.isMobile() && (
											<div className="buttonContainer">
												{swipeStore.getSwipeIndex() !== 0 && (
													<Button
														className="swipeButton"
														variant="outlined"
														onClick={() => swipeStore.decreaseSwipeIndex()}>
														Edellinen
													</Button>
												)}
												{swipeStore.getSwipeIndex() === 0 && <div className="swipeButton" />}
												<Button
													className="swipeButton"
													variant="outlined"
													onClick={() => swipeStore.increaseSwipeIndex()}>
													Seuraava
												</Button>
											</div>
										)}
									</div>
								);
							} else if (question.type === 'multipleNames') {
								return (
									<div key={question.questionId} className="questionWrapper">
										<div className="adultNamesContainer">
											<NameSelector
												key={question.questionId}
												question={question}
												questionIndex={index}
												questionCount={
													questionnaireStore.getAllQuestionnaireQuestions(questionnaireStore.getCurrent()).length
												}
											/>
										</div>
										{mobileStore.isMobile() && (
											<div className="buttonContainer">
												{swipeStore.getSwipeIndex() !== 0 && (
													<Button
														className="swipeButton"
														variant="outlined"
														onClick={() => swipeStore.decreaseSwipeIndex()}>
														Edellinen
													</Button>
												)}
												{swipeStore.getSwipeIndex() === 0 && <div className="swipeButton" />}
												<Button
													className="swipeButton"
													variant="outlined"
													onClick={() => swipeStore.increaseSwipeIndex()}>
													Seuraava
												</Button>
											</div>
										)}
									</div>
								);
							}
							return null;
						})}
						<div className="panelWrapper">
							<div className="panel neuvokasPaperMobile">
								<div>Kiitos vastauksista!</div>
								<Button className="endQuestionnaireButton neuvokasButton" onClick={this.handleButton}>
									Valmis
								</Button>
							</div>
							{mobileStore.isMobile() && (
								<div className="buttonContainer">
									{swipeStore.getSwipeIndex() !== 0 && (
										<Button className="swipeButton" variant="outlined" onClick={() => swipeStore.decreaseSwipeIndex()}>
											Edellinen
										</Button>
									)}
									<div className="swipeButton" />
								</div>
							)}
						</div>
					</SwipeableViews>
				</div>
			);
		} else {
			return (
				<div className="questionnaireWrapper ">
					{questionnaireStore.getAllQuestionnairesBySection(questionnaireStore.getCurrent()).map(section => {
						return (
							<div key={section.sectionId} className="sectionContainer">
								<SectionWrapper section={section} />
							</div>
						);
					})}
					<div className="endQuestionnaireButtonWrapper">
						<Button className="endQuestionnaireButton neuvokasButton" onClick={this.handleButton}>
							Valmis
						</Button>
					</div>
				</div>
			);
		}
	}
}

export default withRouter(QuestionnaireView);
