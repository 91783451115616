import React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import './ChoiceRadio.scss';
import Balls from '../balls/Balls';

@inject('swipeStore', 'questionnaireStore')
@observer
class ChoiceRadio extends React.Component {
	@action
	handleChangeSelected = selectedId => event => {
		const { swipeStore, question, index, questionnaireStore } = this.props;
		if (index !== undefined) {
			if (question.selectedId[index] === event.target.value) {
				question.selectedId[index] = '';
			} else {
				question.selectedId[index] = event.target.value;
			}
		} else {
			if (question.selectedId === event.target.value) {
				question.selectedId = '';
			} else {
				question.selectedId = event.target.value;
			}
		}
		if (!questionnaireStore.isAdultQuestionnaire()) {
			swipeStore.increaseSwipeIndex();
		}
	};

	render() {
		const { selectedId, questionId, index, questionnaireStore } = this.props;

		// 							<span className="checkedMark" />
		return (
			<div className="choiceRadio">
				<form
					action=""
					className={questionnaireStore.isAdultQuestionnaire() ? 'choiceRadioForm verticalButtons' : 'choiceRadioForm'}>
					<label htmlFor={questionId + index + 'green'} className="container">
						<input
							id={questionId + index + 'green'}
							type="radio"
							name="gender"
							value="1"
							readOnly
							checked={selectedId === '1'}
							onClick={this.handleChangeSelected(selectedId)}
						/>
						<Balls color="green" selected={selectedId === '1'} />
					</label>

					<label htmlFor={questionId + index + 'yellow'} className="container">
						<input
							id={questionId + index + 'yellow'}
							type="radio"
							name="gender"
							value="2"
							readOnly
							checked={selectedId === '2'}
							onClick={this.handleChangeSelected(selectedId)}
						/>
						<Balls color="yellow" selected={selectedId === '2'} />
					</label>
					<label htmlFor={questionId + index + 'red'} className="container">
						<input
							id={questionId + index + 'red'}
							type="radio"
							name="gender"
							value="3"
							readOnly
							checked={selectedId === '3'}
							onClick={this.handleChangeSelected(selectedId)}
						/>
						<Balls color="red" selected={selectedId === '3'} />
					</label>
				</form>
			</div>
		);
	}
}
export default ChoiceRadio;
