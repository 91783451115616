import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router';
import { Provider } from 'mobx-react';
import './i18n';
import CircularProgress from '@material-ui/core/CircularProgress';

import { createMemoryHistory } from 'history';

import questionnaireJson from './questionnaire.json';

import questionnaireStore from './stores/QuestionnaireStore';
import topBarStore from './stores/TopBarStore';
import swipeStore from './stores/SwipeStore';
import drawerStore from './stores/DrawerStore';
import dialogStore from './stores/DialogStore';
import localizationStore from './stores/LocalizationStore';
import mobileStore from './stores/MobileStore';
import pdfStore from './stores/PdfStore';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

let history = createMemoryHistory();
const Loader = () => (
	<div>
		<CircularProgress className="loadingSpinner" />
	</div>
);

ReactDOM.render(
	<Provider
		questionnaireStore={questionnaireStore}
		topBarStore={topBarStore}
		swipeStore={swipeStore}
		drawerStore={drawerStore}
		dialogStore={dialogStore}
		localizationStore={localizationStore}
		mobileStore={mobileStore}
		pdfStore={pdfStore}>
		<Router history={history}>
			<Suspense fallback={<Loader />}>
				<ScrollToTop>
					<App>{questionnaireStore.addQuestinnaires(JSON.stringify(questionnaireJson))}</App>
				</ScrollToTop>
			</Suspense>
		</Router>
	</Provider>,
	document.getElementById('root')
);

serviceWorker.unregister();
