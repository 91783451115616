import { observable, action } from 'mobx';

export class DialogStore {
	@observable
	open = false;

	@observable
	dialogOpen = false;

	@observable
	infoModal = false;
	@observable
	infoModalText = '';
	@observable
	infoModalLink = '';

	@action
	setDialog(open) {
		this.open = open;
	}

	@action
	setInfoModalState(state, text, link) {
		this.infoModal = state;
		this.infoModalText = text;
		this.infoModalLink = link;
	}
	isInfoModalOpen() {
		return this.infoModal;
	}

	isOpen() {
		return this.open;
	}

	@action
	setDialogState(state) {
		this.open = state;
	}

	isDialogOpen() {
		return this.dialogOpen;
	}
}

const drawerStore = new DialogStore();
export default drawerStore;
