import React from 'react';

import { withTranslation } from 'react-i18next';

import './ColorInfo.scss';

import { observer, inject } from 'mobx-react';
import Balls from '../balls/Balls';

@inject('mobileStore')
@observer
class ColorInfo extends React.Component {
	render() {
		return (
			<div className="colorInfo">
				<div className="neuvokasText">
					<div>
						<div className="colorTitleWrapper">
							<Balls color="green" className="ball" />
							Vihreä valinta
						</div>
						<div className="colorDescription">Näin meillä jo tehdään</div>
					</div>

					<div>
						<div className="colorTitleWrapper">
							<Balls color="yellow" className="ball" />
							Keltainen valinta
						</div>
						<div className="colorDescription">Tähän haluaisimme panostaa</div>
					</div>
					<div>
						<div className="colorTitleWrapper">
							<Balls color="red" className="ball" />
							Punainen valinta
						</div>
						<div className="colorDescription">Tämä ei juuri nyt ole meille ajankohtaista</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(ColorInfo);
