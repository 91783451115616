import React from 'react';

import './TopBar.scss';
import { inject, observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { observable, action } from 'mobx';
import { withTranslation } from 'react-i18next';
import LanguageIcon from '@material-ui/icons/Language';

@inject('topBarStore', 'drawerStore', 'localizationStore')
@observer
class TopBar extends React.Component {
	@observable
	anchorEl = null;

	handleMenuButton = event => {
		const { drawerStore } = this.props;
		drawerStore.setDrawer(true);
	};

	@action
	handleClick = event => {
		this.anchorEl = event.currentTarget;
	};

	@action
	handleClose = value => event => {
		const { localizationStore } = this.props;
		this.anchorEl = null;
		localizationStore.changeLanguage(value);
	};

	render() {
		const { topBarStore, t } = this.props;
		return (
			<div className={'topBar ' + topBarStore.getTopBarColor()}>
				<div className="buttonWrapper">
					<IconButton
						aria-label="Valikko"
						className={'topBarButton ' + topBarStore.getTopBarColor()}
						onClick={this.handleMenuButton}>
						<MenuIcon />
					</IconButton>
				</div>
				<div className="neuvokasTopBarTitle">{t(topBarStore.getTopBarTitle())}</div>

				{false && (
					<div className="languageMenu">
						<div className="buttonWrapper">
							<IconButton
								className={'languageIcon ' + topBarStore.getTopBarColor()}
								aria-owns={this.anchorEl ? 'simple-menu' : undefined}
								aria-haspopup="true"
								onClick={this.handleClick}>
								<LanguageIcon />
							</IconButton>
						</div>
						<Menu
							disableAutoFocusItem
							id="simple-menu"
							anchorEl={this.anchorEl}
							open={Boolean(this.anchorEl)}
							onClose={this.handleClose}
							onChange={this.handleClose}>
							<MenuItem classes={{ root: 'languageItem' }} onClick={this.handleClose('fi')}>
								{t('languages.fi')}
							</MenuItem>
							<MenuItem classes={{ root: 'languageItem' }} onClick={this.handleClose('sv')}>
								{t('languages.sv')}
							</MenuItem>
						</Menu>
					</div>
				)}
				<div className="buttonWrapper" />
			</div>
		);
	}
}
export default withTranslation()(TopBar);
