import { observable, action } from 'mobx';

export class SwipeStore {
	@observable
	swipeIndex = 0;

	@action
	increaseSwipeIndex() {
		this.swipeIndex = this.swipeIndex + 1;
	}
	@action
	decreaseSwipeIndex() {
		this.swipeIndex = this.swipeIndex - 1;
	}

	@action
	setSwipeIndex(index) {
		this.swipeIndex = index;
	}

	@action
	resetSwipeIndex() {
		this.swipeIndex = 0;
	}

	getSwipeIndex() {
		return this.swipeIndex;
	}
}

const swipeStore = new SwipeStore();
export default swipeStore;
