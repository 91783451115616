import React from 'react';
import { withTranslation } from 'react-i18next';
import './ChoiceQuestion.scss';
import { observer, inject } from 'mobx-react';
import ChoiceRadio from '../choiceRadio/ChoiceRadio';
import { Button, TextField } from '@material-ui/core';
import { has } from 'lodash';
import { toJS, action } from 'mobx';

@inject('mobileStore', 'dialogStore', 'swipeStore', 'questionnaireStore')
@observer
class ChoiceQuestion extends React.Component {
	handleColorDialog = () => {
		this.props.dialogStore.setDialogState(true);
	};

	@action
	handleFollowUpChange = e => {
		console.log('changing thinkgs');
		this.props.question.followUpAnswer = e.target.value;
	};

	handleMultipleFollowUpChange = (index, e) => {
		this.props.question.followUpAnswer[index] = e.target.value;
	};

	render() {
		const { t, question, mobileStore, questionCount, questionIndex, swipeStore, questionnaireStore } = this.props;
		/** Tän vois tabletissa näyttää
		 * 	<IconButton className="questionMarkButton" aria-label="show info" onClick={this.handleColorDialog}>
							<div className="questionMark">?</div>
						</IconButton>
		 */
		const adultNames = questionnaireStore.getNames();
		return (
			<div className="choiceQuestion">
				<div className={mobileStore.isMobile() ? 'cardContainer neuvokasPaperMobile' : 'cardContainer'}>
					<div className="numberWrapper">
						{mobileStore.isMobile() && t('common.question') + ' '}
						{questionIndex + 1} / {questionCount}
					</div>
					<div className="questionTextWrapper">{t('questions.' + question.questionId)}</div>
					<div className="radioWrapper">
						{Array.isArray(toJS(question.selectedId)) &&
							question.selectedId.map((selected, index) => {
								return (
									<div key={index} className="multipleRadioContainer">
										{mobileStore.isMobile() && <div className="adultNameWrapper">{adultNames[index].text}</div>}
										<div className="radioContainerWrapper">
											<ChoiceRadio
												question={question}
												index={index}
												selectedId={selected}
												questionId={question.questionId}
											/>
											{question.selectedId.length - 1 > index && <span className="radioDivider" />}
										</div>
									</div>
								);
							})}
						{!Array.isArray(toJS(question.selectedId)) && (
							<ChoiceRadio question={question} selectedId={question.selectedId} questionId={question.questionId} />
						)}
					</div>
				</div>
				{mobileStore.isMobile() && (
					<div className="buttonContainer">
						{swipeStore.getSwipeIndex() !== 0 && (
							<Button className="swipeButton" variant="outlined" onClick={() => swipeStore.decreaseSwipeIndex()}>
								Edellinen
							</Button>
						)}
						{swipeStore.getSwipeIndex() === 0 && <div className="swipeButton" />}
						<Button className="swipeButton" variant="outlined" onClick={() => swipeStore.increaseSwipeIndex()}>
							Seuraava
						</Button>
					</div>
				)}

				{has(question, 'followUpQuestion') &&
					toJS(question.selectedId) !== '' &&
					!questionnaireStore.isAdultQuestionnaire() && (
						<div className="followUpQuestionWrapper">
							<div className="followUpQuestion">{t('questions.' + question.followUpQuestion)}</div>{' '}
							<TextField
								className="followUpTextField"
								onChange={this.handleFollowUpChange}
								value={question.followUpAnswer}
							/>
						</div>
					)}
				{has(question, 'followUpQuestion') &&
					toJS(question.selectedId) !== '' &&
					questionnaireStore.isAdultQuestionnaire() &&
					adultNames.map((name, index) => {
						return (
							toJS(question.selectedId[index]) !== '' && (
								<div className="multipleFollowUpQuestionWrapper">
									{name.text.toUpperCase() + ': '}
									<div className="multipleFollowUpQuestion">{t('questions.' + question.followUpQuestion)}</div>{' '}
									<TextField
										className="multipleFollowUpTextField"
										onChange={e => this.handleMultipleFollowUpChange(index, e)}
										value={question.followUpAnswer[index]}
									/>
								</div>
							)
						);
					})}
			</div>
		);
	}
}
export default withTranslation()(ChoiceQuestion);
