import React from 'react';

import { withTranslation } from 'react-i18next';

import './ScrollingSideInfo.scss';

import { observer, inject } from 'mobx-react';

import ColorInfo from '../colorInfo/ColorInfo';

@inject('dialogStore')
@observer
class ScrollingSideInfo extends React.Component {
	handleClose = () => {
		this.props.dialogStore.setDialogState(false);
	};

	render() {
		return (
			<div className="scrollingSideInfo">
				<ColorInfo />
			</div>
		);
	}
}
export default withTranslation()(ScrollingSideInfo);
