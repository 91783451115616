import React from 'react';

import './OpenQuestion.scss';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { action } from 'mobx';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';

@observer
@inject('mobileStore', 'swipeStore')
class OpenQuestion extends React.Component {
	@action
	handleChange = e => {
		const { question } = this.props;
		question.answer = e.target.value;
	};

	render() {
		const { mobileStore, question, t, swipeStore } = this.props;
		return (
			<div className="openQuestion">
				<div className={mobileStore.isMobile() ? 'cardContainer neuvokasPaperMobile' : 'cardContainer'}>
					<div className="openTextAnswer">{t('questions.' + question.questionId)}</div>
					<TextField
						className="openQuestionTextWrapper"
						id="outlined-full-width"
						label="Vastaus"
						style={{ margin: 8 }}
						margin="normal"
						variant="outlined"
						InputLabelProps={
							mobileStore.isMobile()
								? {
										className: 'neuvokasQuestion'
								  }
								: { className: 'neuvokasQuestion' }
						}
						InputProps={{
							className: 'openQuestionInput',
							classes: {
								notchedOutline: 'openQuestionTextWrapper'
							}
						}}
						rows={mobileStore.isMobile() ? '3' : '2'}
						multiline
						onChange={this.handleChange}
						defaultValue={question.answer}
					/>
				</div>
				{mobileStore.isMobile() && (
					<div className="buttonContainer">
						{swipeStore.getSwipeIndex() === 0 && (
							<Button className="swipeButton" variant="outlined" onClick={() => swipeStore.decreaseSwipeIndex()}>
								Edellinen
							</Button>
						)}
						{swipeStore.getSwipeIndex() === 0 && (
							<Button className="swipeButton" variant="outlined" onClick={() => swipeStore.increaseSwipeIndex()}>
								Seuraava
							</Button>
						)}
					</div>
				)}
			</div>
		);
	}
}
export default withTranslation()(OpenQuestion);
