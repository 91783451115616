import React from 'react';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import './InfoModal.scss';

@inject('dialogStore')
@observer
class InfoModal extends React.Component {
	handleClose = () => {
		this.props.dialogStore.setInfoModalState(false);
	};

	render() {
		const { dialogStore } = this.props;

		return (
			<Dialog
				className="extraInfoDialog"
				open={dialogStore.isInfoModalOpen()}
				onClose={this.handleClose}
				maxWidth={false}
				aria-labelledby="simple-dialog-title"
				PaperProps={{ className: 'dialogPaperWrapper' }}>
				<IconButton className="closeButton" aria-label="Delete" onClick={this.handleClose}>
					<CloseIcon />
				</IconButton>
				<div className="infoWrapper">
					<div className="title neuvokasHeadline">Lisätietoa kysymyksestä</div>

					{<img className="startImage" src={require('./arki.png')} alt="" />}

					<div className="infoTextWrapper neuvokasText">{dialogStore.infoModalText}</div>
					<div className="infoLinkWrapper neuvokasText">
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={dialogStore.infoModalLink}
							title="{dialogStore.infoModalLink}">
							Linkki
						</a>
					</div>
				</div>
			</Dialog>
		);
	}
}
export default withTranslation()(InfoModal);
