import React from 'react';

import './NameSelector.scss';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { action } from 'mobx';
import TextField from '@material-ui/core/TextField';
import { List } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

@observer
@inject('mobileStore', 'questionnaireStore')
class NameSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = { name: { id: '', text: '' } };
	}

	handleKeyPress = e => {
		if (e.key === 'Enter') {
			this.addName();
		}
	};

	@action
	addName = () => {
		if (this.state.name.text !== '') {
			this.props.question.answer.push(this.state.name);
			this.setState({ name: { id: '', text: '' } });
			this.props.questionnaireStore.setNumberOfAdults('up');
		}
	};

	@action
	deleteName = idToDelete => {
		const index = this.props.question.answer.findIndex(name => name.id === idToDelete);
		this.props.question.answer = this.props.question.answer.filter(name => name.id !== idToDelete);
		this.props.questionnaireStore.setNumberOfAdults('down', index);

		this.forceUpdate();
	};

	render() {
		const { mobileStore, question, t } = this.props;
		return (
			<div className="nameSelector">
				<div className={mobileStore.isMobile() ? 'nameSelectorContainer neuvokasPaperMobile' : 'nameSelectorContainer'}>
					<div className="introText">
						Lisää vanhempi niin näet vastausvalinnat. Voit lisätä kortille enintään kolme täyttäjää.
					</div>
					<Grid container spacing={8} alignItems="center" className="nameSelectorTextContainer">
						<Grid item>
							<TextField
								className="nameSelectorTextWrapper"
								id="outlined-full-width"
								label={t('questions.' + question.questionId)}
								style={{ margin: 0 }}
								margin="normal"
								disabled={question.text || question.answer.length >= 3}
								InputLabelProps={
									mobileStore.isMobile()
										? {
												className: 'neuvokasQuestion'
										  }
										: { className: 'neuvokasQuestion' }
								}
								InputProps={{
									className: 'nameSelectorInput',

									inputProps: {
										maxLength: 12
									}
								}}
								onChange={e => this.setState({ name: { id: Math.random() * 10000, text: e.target.value } })}
								value={this.state.name.text}
								onKeyPress={this.handleKeyPress}
							/>
						</Grid>
						<Grid item>
							<Fab
								aria-label="Add"
								size="small"
								color="primary"
								disabled={this.state.name.text === '' || question.answer.length >= 3}
								className="nameSelectorAddIcon"
								onClick={this.addName}>
								<AddIcon />
							</Fab>
						</Grid>
					</Grid>
					<List className="nameList">
						{question.answer.map(name => {
							return (
								<ListItem key={name.id} button className="nameListItem">
									<ListItemIcon>
										<SupervisorAccountIcon />
									</ListItemIcon>
									<ListItemText primary={name.text} />
									<IconButton aria-label="Delete" onClick={() => this.deleteName(name.id)}>
										<DeleteIcon />
									</IconButton>
								</ListItem>
							);
						})}
					</List>
				</div>
			</div>
		);
	}
}
export default withTranslation()(NameSelector);
