import React from 'react';

import './SideBar.scss';
import { inject, observer } from 'mobx-react';

import { withTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from 'react-router-dom';
import { action } from 'mobx';

@inject('topBarStore', 'dialogStore', 'localizationStore', 'questionnaireStore', 'drawerStore', 'swipeStore')
@observer
class SideBar extends React.Component {
	@action
	handleClick = questionnaireName => {
		this.props.questionnaireStore.setCurrent(questionnaireName);
		this.props.drawerStore.setDrawer(false);
		this.props.swipeStore.resetSwipeIndex();
		this.props.history.push('/main/questionnaire');
	};

	handleFrontPage = () => {
		this.props.drawerStore.setDrawer(false);
		this.props.swipeStore.resetSwipeIndex();
		this.props.history.push('/main/beginning');
	};

	handleColorInformation = () => {
		this.props.dialogStore.setDialogState(true);
	};

	render() {
		const { questionnaireStore } = this.props;

		return (
			<div className="sideBar">
				<div>
					<div className="logoWrapper">
						<img className="drawerLogo" src={require('./neuvokasukkeli.png')} alt="neuvokasimage" />
					</div>

					<List className="navigationList">
						<ListItem classes={{ root: 'menuItem' }} button key={'colorCodingListItem'} onClick={this.handleFrontPage}>
							<ListItemText disableTypography>Etusivu</ListItemText>
						</ListItem>
					</List>
					<Divider className="navigationListDivider" />
					<List className="navigationList">
						{questionnaireStore.getQuestionnaires().map(questionnaire => (
							<ListItem
								classes={{ root: 'menuItem' }}
								button
								key={questionnaire.id + 'listItem'}
								onClick={() => this.handleClick(questionnaire.id)}>
								<ListItemText disableTypography>{questionnaire.full}</ListItemText>
							</ListItem>
						))}
					</List>
					<Divider className="navigationListDivider" />
					<List className="navigationList">
						<ListItem
							classes={{ root: 'menuItem' }}
							button
							key={'colorCodingListItem'}
							onClick={this.handleColorInformation}>
							<ListItemText disableTypography>Täyttöohje</ListItemText>
						</ListItem>
					</List>
				</div>
				<div className="logoWrapper">
					<img className="neuvokasLogo" src={require('./Neuvokas_ensisijainen_logo6_rgb_w.png')} alt="sydänliitto" />
				</div>
			</div>
		);
	}
}
export default withRouter(withTranslation()(SideBar));
