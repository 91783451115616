import { observable, action } from 'mobx';

export class MobileStore {
	@observable
	mobile = false;

	@action
	setWidth(width) {
		this.mobile = width <= 800 ? true : false;
	}

	isMobile() {
		return this.mobile;
	}
}

const mobileStore = new MobileStore();
export default mobileStore;
