import React from 'react';
import { withTranslation } from 'react-i18next';
import './ModalPopper.scss';
import { observer, inject } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import Instructions from '../instructions/Instructions';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

@inject('dialogStore')
@observer
class ModalPopper extends React.Component {
	handleClose = () => {
		this.props.dialogStore.setDialogState(false);
	};

	render() {
		const { dialogStore } = this.props;

		return (
			<Dialog
				className="infoDialog"
				open={dialogStore.isOpen()}
				onClose={this.handleClose}
				maxWidth={false}
				aria-labelledby="simple-dialog-title"
				PaperProps={{ className: 'dialogPaperWrapper' }}>
				<IconButton className="closeButton" aria-label="Delete" onClick={this.handleClose}>
					<CloseIcon />
				</IconButton>
				<div className="instructionsWrapper">
					<Instructions />
				</div>
			</Dialog>
		);
	}
}
export default withTranslation()(ModalPopper);
