import React from 'react';

import { withTranslation } from 'react-i18next';

import './Instructions.scss';

import { observer, inject } from 'mobx-react';
import Balls from '../balls/Balls';

@inject('mobileStore')
@observer
class Instructions extends React.Component {
	render() {
		return (
			<div className="instructions">
				<div className="neuvokasText">
					Täyttämällä Meidän perheen -kortin löydätte juuri teidän perheenne vahvuudet arjen keskellä. Valitsemanne
					värivastaukset kertovat kuinka usein asia toteutuu arjessanne:
				</div>

				<div className="neuvokasText colorContainer">
					<Balls color="green" />
					Vihreä valinta = Näin meillä jo tehdään
					<br />
					<Balls color="yellow" />
					Keltainen valinta = Tähän haluaisimme panostaa
					<br />
					<Balls color="red" />
					Punainen valinta = Tämä ei juuri nyt ole meille ajankohtaista
					<br />
					<br />
				</div>
				<div className="neuvokasText">
					Tavoitteena ei ole saada kaikkia vastauksia vihreäksi, vaan auttaa huomaamaan mihin asioihin arjessanne olette
					perheenä tyytyväisiä ja mitkä asiat kaipaisivat positiivista huomiotanne. Mikäli haluatte tarkemmin
					tarkastella omia tai lapsen liikunta- tai ruokailutottumuksia, voitte jatkaa jokaisen perheenjäsenen omaan
					Neuvokas perhe -korttiin.
				</div>
			</div>
		);
	}
}
export default withTranslation()(Instructions);
